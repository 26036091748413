.bgPrimaryDark {
    background-color: var(--primaryDark);
}
.bgDark {
    background-color: var(--dark);
}
.bgH4 {
    position: relative;
    padding-bottom: 13px;
}
.leftH6 {
    margin-top: 39px;
    padding-bottom: 8px;
}
.rightH6, .section1 .rightH6 + .buttons {
    margin-top: 26px;
}
.justifyEnd {
    justify-content: flex-end;
}
.justifyEnd > div {
    max-width: 506px;
}
.inputgroup {
    margin-bottom: 18px;
}
.fieldbox {
    position: relative;
    width: 100%;
    background-color: var(--white);
}
.fieldbox input::-webkit-outer-spin-button,
.fieldbox input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.fieldbox input[type=number] {
  -moz-appearance: textfield;
}
.fieldbox input {
    width: 100%;
    height: var(--inputHeight);
    font-size: 20px;
    color: var(--black);
    padding-top: 0;
    padding-bottom: 0;
    padding-left: var(--inputHPadding);
    padding-right: calc(var(--inputHPadding) + 50px);
    border: 0;
    outline: 0;
    box-shadow: none;
    background: none;
}
.fieldbox .ikon {
    position: absolute;
    top: 50%;
    right: var(--inputHPadding);
    transform: translateY(-50%);
}
.fieldbox svg {
    fill: var(--black);
}
.fieldbox input + label {
    position: absolute;
    top: 50%;
    left: var(--inputHPadding);
    color: var(--gray);
    transition: all ease 0.3s;
}
.fieldbox input:placeholder-shown + label,
.fieldbox input:not(:focus) + label {
    transform: translateY(-50%);
    font-size: 16px;
}
.fieldbox input:focus + label,
.fieldbox input:not(:placeholder-shown) + label {
    transform: translateY(-130%);
    font-size: 14px;
}
.fieldbox input:focus,
.fieldbox input:not(:placeholder-shown) {
    padding-top: 30px;
}
.chatButton {
    position: fixed;
    right: 29px;
    bottom: 83px;
    width: 60px;
    height: 60px;
    border-radius: 30px;
    line-height: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--black);
    cursor: pointer;
    z-index: calc(var(--headerIndex) + 117);
}
.chatButton svg {
    fill: var(--white);
}

@media (max-width: 767px) {
    .fieldbox input {
        padding-right: calc(var(--inputHPadding) + 33px);
    }
    .fieldbox .ikon {
        right: calc(var(--inputHPadding) - 7px);
        transform: translateY(-50%) scale(0.7);
    }
}