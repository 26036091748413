.section {
  width: 100%;
  background-color: var(--white);
  /* background-image: url(./../../../assets/images/bg1_forecast.png);
    background-repeat: no-repeat;
    background-position: top right;
    background-size: auto; */
}
.article {
  padding: var(--sectionVPadding) 0;
  color: var(--black);
}
.content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.content button {
  margin-top: 24px;
}
.imgbox {
  line-height: 0;
  display: flex;
  justify-content: center;
}
.imgbox img {
  max-height: 600px;
}
.imgbox1 {
  margin-left: 46%;
  margin-bottom: -89px;
}

@media (max-width: 991px) {
  .section {
    background-size: 23.84% auto;
  }
  .article {
    padding: var(--sectionVPadding) 0;
  }
  .article h2 {
    padding: 0 25px;
    text-align: center;
  }
  .content {
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }
  .content button {
    margin-right: auto;
    margin-left: auto;
  }
  .content h2 {
    display: none;
  }
  .imgbox {
    margin-top: 24px;
  }
  .imgbox img {
    width: 36%;
    max-height: auto;
  }
  .imgbox1 {
    width: 51%;
    margin-top: 40px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: -89px;
  }
}
