:root {
    
    --primary: #A461D8;
    --primaryDark: #7A30B3;
    --dark: #171725;
    --black: #0B0410;
    --white: #FFFFFF;
    --gray: #666666;
    --pink: #F661BD;
    --yellow: #FBED20;
    --green: #4BF35C;
    --green1: #66C37E;
    --blue: #4964F9;
    --orange: #FE7A0D;
    --magenta: #B62FA8;
    --shadowBlack: #040406;
    --shadowTitle: #431478;
    --shadowTitle1: rgba(255, 255, 255, 0.12);
    --whiteO90: rgba(255, 255, 255, 0.9);

    --articleHPadding: 20px;
    --articleMaxWidth: 100%;
    --sectionVPadding: 60px;
    --buttonWidth: 236px;
    --buttonHeight: 56px;
    --buttonLgWidth: 274px;
    --buttonLgHeight: 56px;
    --inputHeight: 90px;
    --inputHPadding: 30px;

    --headerPosition: absolute;
    --headerIndex: 101;
    --headerTop: 46px;
    --headerTopSpace: 57.35px;
    --headerLeft: 0;
    --headerWidth: 100%;
    --headerHeight: 30.5px;
    --logoWidth: 133px;
    --logoHeight: 29px;
    --header2Height: 100px;

    --fontRegular: 'SFProTextRegular';
    --fontMedium: 'SFProTextMedium';
    --fontSemiBold: 'SFProTextSemibold';
    --fontBold: 'SFProTextBold';

}

@media (max-width: 767px) {
    :root {
        --inputHeight: 60px;
        --inputHPadding: 10px;
    }
}
@media (min-width: 992px) {
    :root {
        --articleHPadding: 50px;
        --header2Height: 176px;
    }
}
@media (min-width: 1366px) {

    :root {
        --articleHPadding: 113px;
        --sectionVPadding: 100px;
        --buttonHeight: 58px;
        --buttonLgWidth: 361px;
        --buttonLgHeight: 98px;

        --headerTop: 57.35px;
    }

}