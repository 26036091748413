@keyframes backgroundZoom {
    0% {
        transform: scale(0);
    }
    25% {
        transform: scale(1);
    }
    75% {
        transform: scale(1);
    }
    100% {
        transform: scale(0);
    }
}
@keyframes shadowTopH1 {
    0% {
        text-shadow: 0px 0px 0 var(--shadowTitle);
    }
    20% {
        text-shadow: 6px 6px 0 var(--shadowTitle);
    }
    50% {
        text-shadow: 6px 6px 0 var(--shadowTitle);
    }
    60% {
        text-shadow: none;
    }
    61% {
        text-shadow: 6px 6px 0 var(--shadowTitle);
    }
    62% {
        text-shadow: none;
    }
    63% {
        text-shadow: 6px 6px 0 var(--shadowTitle);
    }
    64% {
        text-shadow: none;
    }
    65% {
        text-shadow: 6px 6px 0 var(--shadowTitle);
    }
    66% {
        text-shadow: none;
    }
    67% {
        text-shadow: 6px 6px 0 var(--shadowTitle);
    }
    100% {
        text-shadow: 6px 6px 0 var(--shadowTitle);
    }
}

@keyframes spanAnimation {
    0% {
        transform:translate(0, 0)
    }
    10% {
        transform:translate(-10px, -5px)
    }
    20% {
        transform:translate(-5px, -10px)
    }
    30% {
        transform:translate(0, -5px)
    }
    40% {
        transform:translate(5px, 0)
    }
    50% {
        transform:translate(10px, 5px)
    }
    60% {
        transform:translate(15px, 10px)
    }
    70% {
        transform:translate(10px, 5px)
    }
    80% {
        transform:translate(5px, 0)
    }
    80% {
        transform:translate(0, -5px)
    }
    100% {
        transform:translate(0, 0)
    }
}
  
@keyframes strokeCreation {
    from {
        stroke-dashoffset: 2000;
    }
    to {
        stroke-dashoffset: 0;
    }
}

@keyframes AppLogoSpin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@keyframes boxShadowPrimary {
    0% {
        box-shadow: 6.98px 6.98px 0 0 var(--white);
    }
    50% {
        box-shadow: -2.98px -2.98px 0 0 var(--white);
    }
    100% {
        box-shadow: 6.98px 6.98px 0 0 var(--white);
    }
}

@keyframes boxShadowPrimary1 {
    0% {
        box-shadow: 6.98px 6.98px 0 0 var(--black);
    }
    50% {
        box-shadow: -2.98px -2.98px 0 0 var(--black);
    }
    100% {
        box-shadow: 6.98px 6.98px 0 0 var(--black);
    }
}

@keyframes boxShadowSecondary {
    0% {
        box-shadow: 6.98px 6.98px 0 0 var(--primary);
    }
    50% {
        box-shadow: -2.98px -2.98px 0 0 var(--primary);
    }
    100% {
        box-shadow: 6.98px 6.98px 0 0 var(--primary);
    }
}