#root {
  position: relative;
  background-color: var(--dark);
  min-height: 100vh;
}
.noscroll,
.noscroll #root {
  overflow-y: hidden;
}
.container,
.containbox {
  max-width: var(--articleMaxWidth);
  margin: 0 auto;
  padding: 0 var(--articleHPadding);
}
.sectionSticky {
  width: 100%;
  position: sticky;
  top: 13px;
}
.flex {
  display: flex;
}
.flexVh {
  display: flex;
  align-items: center;
  height: 100vh;
}
.flexVh2 {
  display: flex;
  align-items: center;
  height: calc(100vh - 50px);
}
.flexColumn {
  display: flex;
  flex-direction: column;
}
.alignItemsEnd {
  align-items: flex-end;
}
.justifyCenter {
  justify-content: center;
}
.alignItemsCenter {
  align-items: center;
}
.textCenter {
  text-align: center;
}
.gridSpace73 {
  margin-right: -36.5px;
  margin-left: -36.5px;
}
.gridSpace73 > div {
  width: 50%;
  padding-right: 36.5px;
  padding-left: 36.5px;
}
.heading1 {
  font-size: 72px;
  text-shadow: 3px 3px 0 var(--shadowTitle);
}
.heading1[color="primary"] {
  text-shadow: 3px 3px 0 var(--shadowTitle1);
}
.buttons {
  display: flex;
  align-items: center;
}
.buttons .button + .button {
  margin-left: 49px;
}
.buttons1 {
  display: flex;
  align-items: center;
}
.buttons1 .img_button + .img_button {
  margin-left: 27px;
}
.topSection h1 {
  animation: shadowTopH1 10s infinite linear;
}
.stroke_anime {
  stroke-dasharray: 2000;
  stroke-dashoffset: 2000;
  animation: strokeCreation 3s linear alternate infinite;
}
@media (prefers-reduced-motion: no-preference) {
  .AppLogo {
    animation: AppLogoSpin infinite 20s linear;
  }
}

.card {
  background-color: var(--white);
  border-radius: 72px;
  display: flex;
  align-items: center;
  /* height: calc(100vh - 231px); */
  overflow: hidden;
  position: sticky;
  top: 0px;
}
.cardLeft {
  max-width: 428px;
  width: 37.5438%;
  margin-left: 7.8947%;
  margin-right: 6.228%;
}
.cardRight {
  max-width: 428px;
  width: 37.5438%;
  margin-left: 7.1929%;
  margin-right: 4.9122%;
}

.imgRight > div:nth-child(1),
.imgLeft > div:nth-child(2) {
  width: 54%;
}
.imgRight > div:nth-child(2),
.imgLeft > div:nth-child(1) {
  width: 41%;
  text-align: center;
}
.content_on_white {
  color: var(--black);
  padding: 60px 0 101px;
}
.content_on_white p:not(.no_space) {
  margin-top: 30px;
}
.content_on_white .subheading {
  margin-left: 30px;
}
.content_on_white .subheading2 {
  margin-left: 50px;
}
.content_on_white .centered {
  margin-top: 30px;
  font-family: var(--fontBold);
}
.content_on_white p:nth-child(1) {
  margin-top: 23px;
}
.content_on_white ul {
  list-style-type: disc;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  margin: 30px 0 0 20px;
  color: var(--primary);
}
.content_on_white .list1 {
  color: var(--black);
}
.content_on_white ul li {
  list-style-type: disc;
  margin: 0 0 0 20px;
}
.content_on_white .button {
  margin-top: 78px;
}
.card_section {
  display: flex;
  max-width: 1100px;
  margin: auto;
}
.card_subscription {
  width: 50%;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  margin: 30px 10px;
  padding: 2em;
  border-radius: 10px;
}
.card_heading {
  text-decoration: underline;
  font-size: 24px;
  font-family: var(--fontBold);
}
.card_content {
  font-size: 14px;
}
.card_starting {
  font-size: 12px;
}
.card_price {
  font-size: 24px;
  font-family: var(--fontBold);
}
.card_per_month {
  font-size: 14px;
  font-family: var(--fontRegular);
}
.card_footer {
  border-top: 1px solid black;
  margin-top: 20px;
  padding-top: 10px;
}
.card_features {
  margin-top: 10px !important;
}
.card_features svg {
  width: 20px;
  height: 20px;
  fill: var(--black);
  margin-right: 10px;
}

.card_features span {
  font-size: 13px;
  font-family: var(--fontRegular);
}

.display-flex {
  display: flex;
}

.display-flex > .card_features {
  width: 50%;
}
.table-wrapper {
  margin: 20px 0px 10px;
  box-shadow: 0px 35px 50px rgba(0, 0, 0, 0.2);
}

.fl-table {
  border-radius: 5px;
  font-size: 12px;
  font-weight: normal;
  border: none;
  border-collapse: collapse;
  /* width: 100%;
  max-width: 100%;
  white-space: nowrap; */
  background-color: white;
}
.featured-link {
  color: var(--primary);
  margin: 0px 3px 0px 3px;
  border: none;
  background-color: inherit;
  font-size: 16px;
  cursor: pointer;
  display: inline-block;
}
.fl-table td,
.fl-table th {
  text-align: left;
  padding: 10px;
}

.fl-table td {
  border-right: 1px solid #f8f8f8;
  font-size: 12px;
}

.fl-table thead th {
  color: black;
}
.access-request-container {
  margin: 20px 0px 0px 0px;
  padding: 3.125rem 4.75rem;
  background-color: #fff;
  box-shadow: 0 3.125rem 3.125rem -1.875rem #f5f6fa;
  border: solid 0.0625rem #8b93a7;
}
.input-group {
  margin-bottom: 1.5rem;
}
.input-group > label {
  font-size: 0.875rem;
  font-weight: 500;
  color: #000;
  display: inline-block;
  margin-bottom: 0.625rem;
  line-height: 1.4;
}
.input-group > input,
select,
textarea {
  text-align: left;
  width: 100%;
  height: 2.5625rem;
  box-sizing: border-box;
  padding: 0 0.9375rem;
  border: solid 0.0625rem #e1e3e6;
  border-radius: 0.1875rem;
  outline: none;
  font-size: 0.875rem;
  background: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  resize: vertical;
  color: #3e3e3e;
  text-align: left;
}
.input-textarea {
  padding: 0.9375rem;
  height: 8.5625rem;
  resize: none;
}
.radio-group {
  font-size: 0.8125rem;
  font-weight: 500;
  color: #363840;
  display: flex;
  flex-direction: row;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  margin-bottom: 0.75rem;
}
.radio-group label {
  margin-left: 10px;
}
.submit-button-container {
  margin-top: 2.5rem;
  margin-bottom: 0;
  height: auto;
  width: 100%;
  text-align: right;
}
.button-5 {
  align-items: center;
  background-clip: padding-box;
  background-color: var(--primary);
  border: 1px solid transparent;
  border-radius: 0.25rem;
  box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  font-family: system-ui, -apple-system, system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: 600;
  justify-content: center;
  line-height: 1.25;
  margin: 0;
  min-height: 3rem;
  padding: calc(0.875rem - 1px) calc(1.5rem - 1px);
  position: relative;
  text-decoration: none;
  transition: all 250ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  width: auto;
}

.button-5:hover,
.button-5:focus {
  background-color: var(--primaryDark);
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
}

.button-5:hover {
  transform: translateY(-1px);
}

.button-5:active {
  background-color: var(--primaryDark);
  box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
  transform: translateY(0);
}
/* Responsive */

@media (max-width: 767px) {
  .fl-table {
    display: block;
    width: 100%;
  }
  .table-wrapper:before {
    content: "Scroll horizontally >";
    display: block;
    text-align: right;
    font-size: 11px;
    color: white;
    padding: 0 0 10px;
  }
  .fl-table thead,
  .fl-table tbody,
  .fl-table thead th {
    display: block;
  }
  .fl-table thead th:last-child {
    border-bottom: none;
  }
  .fl-table thead {
    float: left;
  }
  .fl-table tbody {
    width: auto;
    position: relative;
    overflow-x: auto;
  }
  .fl-table td,
  .fl-table th {
    padding: 20px 0.625em 0.625em 0.625em;
    height: 60px;
    vertical-align: middle;
    box-sizing: border-box;
    overflow-x: hidden;
    overflow-y: auto;
    width: 120px;
    font-size: 13px;
    text-overflow: ellipsis;
  }
  .fl-table thead th {
    text-align: left;
    border-bottom: 1px solid #f7f7f9;
  }
  .fl-table tbody tr {
    display: table-cell;
  }
  .fl-table tbody tr:nth-child(odd) {
    background: none;
  }
  .fl-table tr:nth-child(even) {
    background: transparent;
  }
  .fl-table tr td:nth-child(odd) {
    background: #f8f8f8;
    border-right: 1px solid #e6e4e4;
  }
  .fl-table tr td:nth-child(even) {
    border-right: 1px solid #e6e4e4;
  }
  .fl-table tbody td {
    display: block;
    text-align: center;
  }
}
@media (max-width: 991px) {
  .mobTabOnly {
    display: block;
  }
  .imgRight > div:nth-child(1),
  .imgLeft > div:nth-child(2) {
    width: 100%;
  }
  .imgRight > div:nth-child(2),
  .imgLeft > div:nth-child(1) {
    width: 100%;
  }
  .card_section {
    flex-wrap: wrap;
  }
  .card_subscription {
    width: 100%;
  }
  .card_features span {
    font-size: 11px;
  }
  .card_features svg {
    width: 15px;
    height: 15px;
  }
}
@media (min-width: 992px) {
  .mobTabOnly {
    display: none;
  }
}
