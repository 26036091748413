/* .headerBefore {
    width: 100%;
    height: var(--headerTopSpace);
    background-color: var(--dark);
} */
.header {
    width: var(--headerWidth);
    padding: 0;
    position: var(--headerPosition);
    top: var(--headerTop);
    left: var(--headerLeft);
    z-index: var(--headerIndex);
}
.flex {
    display: flex;
    width: 100%;
    height: var(--headerHeight);
    align-items: center;
    justify-content: space-between;
}
.colEqual {
    width: 57.58px;
}
.link {
    box-shadow: none;
    border: 0;
    outline: 0;
    padding: 0;
    background: none;
    cursor: pointer;
}
.menuIkon svg {
    width: 39px;
    height: 20.37px;
}
.logo {
    display: block;
    line-height: 0;
}
.logo a {
    display: block;
}
.logo svg {
    fill: var(--white);
    width: var(--logoWidth);
    height: var(--logoHeight);
    pointer-events: none;
}
.flexLink {
    display: flex;
    align-items: center;
}
.profileIkon svg {
    width: 28.72px;
    height: 33.82px;
}
.ddArrowIkon svg {
    width: 13.86px;
    height: 8.15px;
    margin-left: 15px;
}

@media (max-width: 991px) {
    .flex {
        justify-content: center;
    }
}

/* header2 */
.header2 {
    background-color: var(--primary);
}
.flexHead2 {
    height: var(--header2Height);
}
.head2Right {
    display: flex;
    align-items: center;
}
.head2Right > div a {
    display: block;
    line-height: 0;
}
.head2Right svg {
    fill: var(--white);
}

@media (max-width: 559px) {
    .head2Right button {
        width: 150px;
        height: 40px;
        font-family: var(--fontBold);
        font-size: 14px;
    }
}
@media (max-width: 991px) {
    .head2Right > div:nth-child(1) {
        display: none;
    }
    .flexHead2 {
        justify-content: space-between;
    }
}
@media (min-width: 992px) {
    .head2Right > div + div {
        margin-left: 7.2474vw;
    }
}
/* /header2 */