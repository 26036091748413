.button {
    position: relative;
    width: var(--buttonWidth);
    height: var(--buttonHeight);
    border-radius: calc(var(--buttonHeight) / 2);
    font-family: var(--fontMedium);
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    outline: 0;
    box-shadow: none;
    cursor: pointer;
    overflow: hidden;
}
.buttonLg {
    width: var(--buttonLgWidth);
    height: var(--buttonLgHeight);
    font-size: 32px;
    border-radius: calc(var(--buttonLgHeight) / 2);
}

.button[color="white-border"] {
    background-color: transparent;
    color: var(--white);
    border: var(--white) 2px solid;
}
.button[color="white-border"].buttonLg {
    border: var(--white) 3.61px solid;
}
.button[color="primary-border"] {
    background-color: transparent;
    color: var(--white);
    border: var(--primary) 2px solid;
}
[color="bg-white"] .button[color="primary-border"] {
    color: var(--primary);
}
.button[color="black-border"] {
    background-color: transparent;
    color: var(--primary);
    border: var(--black) 2px solid;
}
.button[color="bg-primary"] {
    background-color: var(--primary);
    color: var(--white);
    border: var(--primary) 2px solid;
}
.button[color="secondary"] {
    background-color: var(--white);
    color: var(--primary);
    box-shadow: 6.98px 6.98px 0 0 var(--primary);
}
.button[color="secondary"]:hover {
    animation: boxShadowSecondary 1s linear;
}
.button[color="secondary"]:active {
    box-shadow: 2.98px 2.98px 0 0 var(--primary);
    transform: translateY(4px);
}
.button[color="dark"] {
    background-color: var(--dark);
    color: var(--white);
    box-shadow: 6.98px 6.98px 0 0 var(--primary);
}
.button[color="dark"]:hover {
    animation: boxShadowSecondary 1s linear;
}
.button[color="dark"]:active {
    box-shadow: 2.98px 2.98px 0 0 var(--primary);
    transform: translateY(4px);
}

.button::after {
    content: "";
    background: rgba(255, 255, 255, 0.2);
    display: block;
    position: absolute;
    left: 0;
    padding-top: 300%;
    padding-left: 400%;
    /* margin-left: -20px !important; */
    margin-top: 120%;
    opacity: 0;
    transition: all 0.8s
}
.button:active::after {
    padding: 0;
    margin: 0;
    opacity: 1;
    transition: 0s;
}
.button[color="secondary"]::after {
    background: rgba(0, 0, 0, 0.2);
}

.img_button {
    height: 52px;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    outline: 0;
    box-shadow: none;
    cursor: pointer;
}
.img_button img {
    width: auto;
    height: 100%;
}

@media (max-width: 991px) {
    .buttonLg {
        font-size: 20px;
    }
}