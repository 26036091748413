@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,700;1,400&display=swap');
@font-face {
    font-family: "SFProTextBold";
    src: local("SFProTextBold"),
     url("./../fonts/sfprotext/SF-Pro-Text-Bold.ttf") format("truetype");
    font-weight: 400;
}
@font-face {
    font-family: "SFProTextMedium";
    src: local("SFProTextMedium"),
     url("./../fonts/sfprotext/SF-Pro-Text-Medium.ttf") format("truetype");
    font-weight: 300;
}
@font-face {
    font-family: "SFProTextRegular";
    src: local("SFProTextRegular"),
     url("./../fonts/sfprotext/SF-Pro-Text-Regular.ttf") format("truetype");
    font-weight: 400;
}
@font-face {
    font-family: "SFProTextSemibold";
    src: local("SFProTextSemibold"),
     url("./../fonts/sfprotext/SF-Pro-Text-Semibold.ttf") format("truetype");
    font-weight: 400;
}

* {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    box-shadow: none;
    list-style-type: none;
    box-sizing: border-box;
}
html, body {
    margin: 0;
    padding: 0;
}
body {
    font-size: 10px;
    font-family: var(--fontMedium);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: var(--white);
}
strong {
    font-family: var(--fontSemiBold);
}
b {
    font-family: var(--fontBold);
}
h1 {
    font-family: var(--fontSemiBold);
    font-weight: 400;
    font-size: 80px;
    letter-spacing: -2px;
}
h2 {
    font-family: var(--fontBold);
    font-weight: 400;
    font-size: 52px;
    letter-spacing: -2.5px;
    margin: 0;
}
h3 {
    font-family: var(--fontRegular);
    font-weight: 400;
    font-size: 40px;
}
h4 {
    font-family: var(--fontMedium);
    font-weight: 400;
    font-size: 24px;
    line-height: 1.5;
}
h5, .h5 {
    font-family: var(--fontMedium);
    font-weight: 400;
    font-size: 18px;
    line-height: 2.1;
    margin-top: 24px;
}
p {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.9;
}
p.h5 {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
}
p strong {
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
}
img {
    border: 0;
    outline: 0;
    max-width: 100%;
}
ol, ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}
a, a:focus, a:hover {
    color: inherit;
    text-decoration: none;
}
[color="primary"] {
    color: var(--primary);
}
[color="dark"] {
    color: var(--dark);
}
[color="bg-white"] {
    background-color: var(--white);
}

@media (max-width: 1365px) {

    h1 {
        font-size: 50px;
    }
    h2 {
        font-size: 30px;
    }
    h3 {
        font-size: 28px;
    }
    h4 {
        font-size: 1.45vw;
    }
    h5, .h5 {
        font-size: 14px;
    }

}