.section {
  width: 100%;
  background-color: var(--black);
}
.article {
  padding: var(--sectionVPadding) 0;
}
.content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.content > div:nth-child(1) {
  width: 46%;
}
.content > div:nth-child(2) {
  width: 48%;
}
.content button {
  margin-top: 24px;
}
.imgbox {
  line-height: 0;
  text-align: center;
}
.imgbox img,
.imgbox1 img {
  max-width: 100%;
}
.imgbox1 {
  line-height: 0;
  margin-left: 11%;
  margin-bottom: -72px;
  margin-top: -100px;
}

@media (max-width: 991px) {
  .article {
    padding: var(--sectionVPadding) 0;
  }
  .article h2 {
    text-align: center;
  }
  .content {
    flex-direction: column-reverse;
    justify-content: center;
    text-align: center;
  }
  .content > div:nth-child(1) {
    width: 100%;
  }
  .content > div:nth-child(2) {
    width: 100%;
  }
  .content button {
    margin-right: auto;
    margin-left: auto;
  }
  .content h2 {
    display: none;
  }
  .imgbox {
    margin-top: 24px;
  }
  .imgbox img {
    width: 66%;
    max-height: auto;
  }
  .imgbox1 {
    width: 55%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    margin-bottom: -45px;
  }
}

@media (min-width: 992px) {
  .imgbox1 {
    width: 70%;
    margin-bottom: -72px;
    margin-top: -30px;
  }
}

@media (min-width: 1600px) {
  .content > div:nth-child(1) {
    width: 54%;
  }
  .content > div:nth-child(2) {
    width: 41%;
  }
  .imgbox1 {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
}
