.section {
    width: 100%;
    background-color: var(--white);
}
.article {
    padding: var(--sectionVPadding) 0;
    color: var(--black);
}
.content {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.content > div {
    width: 46%;
}
.imgbox {
    line-height: 0;
}
.content button {
    margin-top: 24px;
}

@media (max-width: 991px) {
    .article h2 {
        text-align: center;
    }
    .content {
        flex-direction: column;
        justify-content: center;
        text-align: center;
    }
    .content > div {
        width: 100%;
    }
    .content button {
        margin-right: auto;
        margin-left: auto;
    }
    .content h2 {
        display: none;
    }
    .imgbox {
        margin-top: 24px;
    }
    .imgbox img {
        width: 70%;
        max-height: auto;
    }
}

@media (min-width: 1600px) {
    .content > div:nth-child(1) {
        width: 41%;
        text-align: center;
    }
    .content > div:nth-child(2) {
        width: 54%;
    }
}