.section {
    position: relative;
    width: 100%;
    background-color: var(--white);
    background-image: url(./../../../assets/images/bg1_smart.png), url(./../../../assets/images/bg2_smart.png);
    background-repeat: no-repeat, no-repeat;
    background-position: top left, top right;
    background-size: 28% auto, 18% auto;
}
.article {
    padding-top: var(--sectionVPadding);
}
.content {
    text-align: center;
    color: var(--black);
    max-width: 846px;
    margin: 0 auto;
}
.badge {
    width: 64px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--black);
    margin: 0 auto 10px;
}
.badge span {
    display: block;
    line-height: 0;
}
.badge svg {
    fill: var(--white);
}
.h5 {
    margin-top: 8px;
}
.imgbox {
    display: flex;
    justify-content: center;
    line-height: 0;
    padding-top: calc(var(--sectionVPadding) / 1.5);
}
.imgbox img {
    max-width: 100%;
}

@media (max-width: 991px) {
    .badge {
        margin-bottom: 13px;
    }
    .badge svg {
        width: 56px;
        height: 13px;
    }
    .h5 {
        margin-top: 13px;
    }
    .imgbox {
        padding-top: 37px;
    }
    .imgbox img {
        max-width: calc(100% - 30px);
    }
}
@media (min-width: 992px) {
    .section {
        background-size: auto, auto;
    }
    .badge {
        width: 102px;
        height: 33px;
    }
}