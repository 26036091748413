.section {
    width: 100%;
    background-color: var(--black);
}
.article {
    padding: calc(var(--sectionVPadding) / 1.5) 0;
}
.content {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.content button {
    margin-top: 24px;
}
.imgbox {
    line-height: 0;
}
.imgbox img {
    width: 100%;
    height: auto;
}

@media (max-width: 991px) {
    .article h2 {
        padding: 0 50px;
        text-align: center;
    }
    .content {
        flex-direction: column-reverse;
        justify-content: center;
        text-align: center;
    }
    .content button {
        margin-right: auto;
        margin-left: auto;
    }
    .content h2 {
        display: none;
    }
    .imgbox {
        margin-top: 24px;
    }
    .imgbox img {
        width: 51%;
    }
}