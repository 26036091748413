.footer {
  width: 100%;
  background-color: var(--primary);
  position: relative;
  background-image: url(./../../assets/images/bg_footer.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  z-index: calc(var(--headerIndex) - 1);
}
.article {
  padding: 50px 0;
}
.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.fooLeft svg {
  fill: var(--white);
  width: var(--logoWidth);
  height: var(--logoHeight);
}
.fooLeft > div:nth-child(1) a {
  display: inline-block;
}
.socials {
  display: none;
  align-items: center;
  margin-top: 24px;
}
.socials svg {
  fill: var(--white);
}
.socials a {
  transition: transform ease 0.3s;
}
.socials a + a {
  margin-left: 13px;
}
.socials a:hover {
  transform: scale(1.25);
}
.foolinks {
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-top: 24px;
}
.foocontactlinks {
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-top: 10px;
}
.foocontacthref {
  display: flex;
}
.foocontacthref svg {
  width: 20px;
  height: 20px;
  margin: auto;
  margin-right: 10px;
}
.foolinks p + p {
  margin-left: 10px;
}
.foocontactlinks p + p {
  margin-left: 10px;
}
.copyRight {
  font-family: var(--fontLight);
  margin-right: 10px;
}
.fooRight button {
  width: calc(var(--buttonWidth) + 90px);
}
.fooRight nav {
  display: flex;
  font-family: var(--fontLight);
  font-size: 16px;
  margin-bottom: 23px;
}
.fooRight nav a + a {
  margin-left: 40px;
}

@media (max-width: 992px) {
  .footer {
    background-image: url(./../../assets/images/bg_footer_mobile.jpg);
  }
  .article {
    padding: 108px 0 50px 0;
  }
  .flex {
    flex-direction: column-reverse;
    justify-content: center;
  }
  .fooLeft > div:nth-child(1) {
    margin-top: 25px;
    display: flex;
    justify-content: center;
  }
  .socials, .foocontactlinks, .foolinks {
    justify-content: center;
  }
  .copyRight {
    text-align: center;
    font-size: 9px;
    margin-right: 5px;
  }
  .foolinks p + p {
    margin-left: 2px;
    font-size: 8px;
  }
  .foocontactlinks p + p {
    margin-left: 5px;
  }
  .foocontactlinks > p, .foocontactlinks > p > a > p {
    font-size: 10px;
  }
  .foocontacthref svg {
    width: 10px;
    height: 10px;
  }
}
