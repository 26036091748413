.backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: var(--black);
    opacity: 0.8;
    z-index: -1;
}
.button {
    background-color: transparent;
    border: 0;
    outline: 0;
    position: fixed;
    top: 85px;
    right: 59px;
    cursor: pointer;
}
.button svg {
    fill: var(--white);
}
.article {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: calc(var(--headerIndex) + 121);
    overflow-y: auto;
}
.modalbox {
    width: 100%;
    max-width: 782px;
    margin: 100px auto;
    padding: 90px 115px;
    background-color: var(--primary);
    border: var(--white) 6px solid;
    border-radius: 40px;
    background-image: url(./../../assets/images/bg1_modal.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
.title {
    text-align: center;
    margin-bottom: 40px;
}
.modalbox button {
    width: 100%;
    margin-top: 24px;
}

.successcontent {
    background-color: var(--whiteO90);
    border-radius: 20px;
    padding: 73px 61px 120px;
}
.iconbox1 {
    text-align: center;
}
.successText {
    font-family: var(--fontRegular);
    font-size: 24px;
    color: var(--black);
    margin-top: 34px;
}

@media (max-width: 1365px) {
    .button {
        top: 20px;
        right: 20px;
    }
    .button svg {
        width: 40px;
        height: 40px;
    }
}

@media (max-width: 991px) {
    .modalbox {
        max-width: calc(100% - 40px);
        margin: 60px auto 40px;
        padding: 25px 20px;
    }
    .successcontent {
        padding: 61px 20px 61px;
    }
}

@media (max-width: 559px) {
    .modalbox {
        border: var(--white) 4px solid;
    }
    .title {
        margin-bottom: 25px;
    }
    .button {
        top: 10px;
        right: auto;
        left: calc(50% - 20px);
    }
}