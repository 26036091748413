.backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: var(--black);
    opacity: 0.8;
    z-index: -1;
}
.closeWrap {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding-bottom: 7px;
}
.button {
    background-color: transparent;
    border: 0;
    outline: 0;
    cursor: pointer;
}
.button svg {
    width: 24px;
    height: 24px;
    fill: var(--black);
}
.article {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: calc(var(--headerIndex) + 121);
    overflow-y: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}
.modalbox {
    width: 100%;
    max-width: 710px;
    margin: 0 auto;
    padding: 13px 31px 44px;
    background-color: var(--white);
    border-radius: 10px;
}
.content {
    display: flex;
    align-items: flex-end;
    color: var(--black);
}
.imgbox {
    margin-right: 18px;
    line-height: 0;
}
.imgbox img {
    width: 133px;
    max-width: 133px;
}
.textbox p {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 1.5;
}
.textbox p a {
    color: var(--primary);
}
.buttons {
    display: flex;
    align-items: center;
    margin-top: 20px;
}
.buttons button + button {
    margin-left: 13px;
}

@media (max-width: 559px) {
    .modalbox {
        border: var(--white) 4px solid;
    }
    .title {
        margin-bottom: 25px;
    }
}

@media (max-width: 767px) {
    .modalbox {
        margin-top: 0;
        margin-bottom: 0;
        padding: 20px 31px 46px;
    }
    .content {
        flex-direction: column;
        align-items: center;
    }
    .imgbox {
        margin-right: 0;
        margin-bottom: 22px;
    }
    .imgbox img {
        width: 71px;
        max-width: 71px;
    }
    .textbox p {
        text-align: center;
    }
    .buttons {
        flex-direction: column;
        margin-top: 34px;
    }
    .buttons button + button {
        margin-left: 0;
        margin-top: 26px;
    }
}

@media (max-width: 991px) {
    .modalbox {
        width: calc(100% - 66px);
        margin: 60px auto 40px;
        padding: 25px 20px;
    }
}