.section {
    position: relative;
    width: 100%;
    background-color: var(--primary);
    background-image: url(./../../../assets/images/bg_banner.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
.content h1 {
    padding-top: 20px;
}
.content h1 span {
    display: block;
}
.content button {
    margin-top: 61px;
    border-radius: calc((var(--buttonLgHeight) - 18px) / 2);
}

@media (max-width: 559px) {}
@media (max-width: 767px) {
    .section article {
        height: 100vh;
        min-height: 667px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }
}
@media (max-width: 991px) {
    .content {
        text-align: center;
        padding-top: calc(var(--headerHeight) + var(--headerTop) + 55px);
    }
    .content button {
        margin-top: 23px;
        margin-left: auto;
        margin-right: auto;
        border-radius: calc(var(--buttonLgHeight) / 2);
    }
    .imgbanner {
        text-align: center;
        margin-top: 23px;
    }
    .img_desk {
        display: none;
    }
    .img_mob {
        display: block;
        margin: 0 auto;
    }
}
@media (max-width: 1199px) {
    .content button {
        font-size: 24px;
    }
}
@media (min-width: 992px) {
    .section {
        height: 100vh;
        min-height: 708px;
        display: flex;
        align-items: center;
    }
    .imgbanner {
        position: absolute;
        top: 0;
        right: 0;
    }
    .imgbanner img {
        height: calc(100vh - 25.75px);
        min-height: calc(708px - 25.75px);
        width: auto;
    }
    .img_desk {
        display: block;
    }
    .img_mob {
        display: none;
    }
}

@media (max-height: 708px) {
    .content {
        padding-top: 30px;
    }
}